.customlist {
  width: 100%;
  background-color: 'background.paper';
  overflow: 'auto';
  padding: '0px';
  border-radius: var(--borderRadius, 4px);
}

.customlist__listitem {
  justify-content: 'space-between';
  display: 'flex';
}

.customlist__listitem__text {
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: 'center';
}

.customlist__listitem__buttons {
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: 'space-between';
  padding-right: 4px;
}

.button:hover {
  border-color: #006494;
}
