.thinking {
  display: flex;
  align-self: flex-start;
  flex-direction: row;
}

.thinking__avatar {
  width: 32px;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thinking__avatar__img {
  width: 24px;
  height: 24px;
  margin: auto;
}

.thinking__chat__bubble {
  margin-left: 6px;
  background-color: #ecf4ff; /* You can change this color as per your design */
  /* Add other chat-bubble styles here */
  border-radius: 8px;
}
.thinking__chat__bubble__carousel {
  width: max-content;
  max-width: 300px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thinking__chat__bubble__stack {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.thinking__chat__bubble__text {
  color: #38485a;
  font-weight: 600;
}

.thinking__chat__bubble__dot {
  width: 20px;
  font-size: 16px;
  margin-left: 2px;
  color: #38485a;
  letter-spacing: 1px;
  font-weight: 900;
}
.thinking__chat__bubble__dot::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}
/* 
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: fit-content;
  height: 20px;
} */
