.rundialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rundialog__title {
  text-transform: none;
  font-weight: 500;
  font-size: 20px;
}

.rundialog__crawlers__chips {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
}
.rundialog__crawlers__chips__chip {
  margin-right: 8px;
  margin-top: 4px;
  background-color: #e8f1f2;
}
