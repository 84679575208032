.tabs__container {
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid #e8f1f2;
}

.first__tab {
  padding: 9px 0px;
  padding-right: 16px;
}
