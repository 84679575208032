.actions__runiconbtn {
  color: #13293d;
  border-radius: var(--borderRadius);
  border: 1px solid #006494;
  width: 36px;
  height: 36px;
}
.actions__runicon {
  width: 20px;
  height: 20px;
}
.actions__editiconbtn {
  border-radius: var(--borderRadius);
  border: 1px solid #006494;
  margin: 0px 16px;
  width: 36px;
  height: 36px;
}
.actions__editicon {
  width: 16px;
  height: 16px;
}
.actions__deleteiconbtn {
  border-radius: var(--borderRadius);
  border: 1px solid #006494;
  width: 36px;
  height: 36px;
}
.actions__deleteicon {
  width: 16px;
  height: 16px;
}
