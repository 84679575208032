.filter__dialog {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filter__dialog__title {
  height: 30px;
  color: #006494;
  font-size: 23px;
  font-weight: 700;
  text-transform: none;
}
.filter__dialog__subtitle {
  height: 21px;
  color: #13293d;
  font-size: 16px;
  font-weight: 500;
}

.filter__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
}
