.onboard_steps_title {
  color: #004b6f;
  font-weight: 700;
  text-transform: none;
}

.onboard__configure__step__subtitle {
  margin-top: 8px;
  /* color: #6a6e73; */
  line-height: 20px;
  text-transform: none;
}
.onboard__teams__configure__steps {
  border: 1px solid #e8f1f2;
  background-color: #f7f7f7;
  padding: 4px 8px;
  border-radius: 4px;
}
.onboard__configure__steps__list {
  margin-top: 4px;
  margin-left: 12px;
}
.onboard__configure__steps__list__item {
  margin: 6px 0;
  line-height: 20px;
  color: #304456;
  font-size: 14px;
  font-weight: 500;
}
.onboard__configure__btn {
  height: 24px;
  min-width: 0px !important;
  width: fit-content !important;
  padding: 2px 8px !important;
  margin-top: 6px;
  margin-left: 12px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  font-size: 12px;
}
