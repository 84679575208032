.chatview {
  width: 100%;
  height: 100vh;
  max-height: 100vh !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow: auto;
}

.chatview__header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.chatview__header__title {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.chatview__header__img {
  width: 70px;
  height: 60px;
}

.chatview__suggested__questions {
  width: 60%;
  align-self: center;
}
.suggested__question__item {
  border: 1px solid #9fb6b8;
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
}
.suggested__question__item:hover {
  border: 1px solid #006494;
}
.suggested__question__item__title {
}
.suggested__question__item__tag {
  background: #e8f1f2;
  width: fit-content;
  border-radius: 50px;
  padding: 2px 12px;
  margin-top: 8px;
}

/* Chatview container */
.chatview__container {
  width: 100%;
  display: flex;
  height: calc(100vh - 128px);
  flex-direction: column;
  align-items: center;
  max-height: 100% !important;
}

/* Messages list */
.chatview__body {
  display: flex;
  height: 100%;
  max-height: 100% !important;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  padding: 0px 5%;
}

/* form */
/* Form.css */
.chatview__form {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding: 10px;
  height: fit-content !important;
  width: 80%;
}

.chatview__form__group {
  display: flex;
  height: 42px;
  justify-content: space-between;
  width: 100%;
}
.chatview__form__clearbtn {
  width: 50px;
  height: 42px;
  min-width: 50px !important;
  /* margin-right: 6px; */
  padding: 0px 8px !important;
  background-color: #ffffff00;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0px 0px 4px !important;
}
.chatview__form__clearbtn:hover {
  background-color: #fff;
}
.chatview__form__clearbtn:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.chatview__form__textarea {
  height: 42px !important;
  width: 100% !important;
  padding: 8px;
  resize: none;
  border: 1px solid #ccc;
  border-left: none;
  border-right: none;
  /* border-radius: 4px; */
  font-size: 16px;
  outline: none;
}

.submit-button {
  min-width: 50px !important;
  padding: 0px 8px !important;
  width: 50px;
  height: 42px;
  color: #fff;
  border: none;
  border-radius: 0px 4px 4px 0px !important;
  cursor: pointer;
  /* margin-left: 6px; */
  background-color: #ffffff00;
  border: 1px solid #ccc;
  border-left: none;
}

.submit-button:hover {
  background-color: #fff;
}

.submit-button:disabled {
  background-color: #fff;
  cursor: not-allowed;
}

.submit-button.active {
  opacity: 1;
}

.chatview__note {
  width: 80%;
  font-size: 12px;
  font-weight: 500;
  text-transform: none;
}
