.tablemenubar__refreshbutton {
  height: 36px;
  padding: 12px;
  font-size: 13px;
  background-color: white;
  border: 1px solid #e8f1f2;
  margin-right: 12px;
}

.tablemenubar__filterbutton {
  height: 36px;
  padding: 12px;
  font-size: 13px;
  background-color: white;
  border: 1px solid #e8f1f2;
}
.tablemenubar__filterbutton.activeFilter {
  border: 1px solid #006494;
  background: #e8f1f2;
}

.tablemenubar__clearfilterbutton {
  height: 36px;
  padding: 11px 12px;
  font-size: 13px;
  cursor: pointer;
  color: #006494;
}
