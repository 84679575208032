:root {
  --borderRadius: 4px;
  --inputBorderColor: rgba(0, 0, 0, 0.23);
}

.loginpage__container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.loginpage__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.loginpage__logo {
  width: 160px;
  height: 130px;
}

.login__inputs {
  width: 500px;
  margin-top: 16px;
  padding: 9px 0px;
  display: flex;
  min-height: 24px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid var(--components-input-outlined-enabled-border, rgba(0, 0, 0, 0.23));
}

.login__btn {
  width: auto;
  margin: 16px 0px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
}
.login__or {
  margin: 0px 6px;
  font-weight: 400;
}

.login__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
