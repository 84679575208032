.delete__dialog {
  display: flex;
  flex-direction: column;
}

.delete__dialog__title {
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
}
/* crawler names */
.deletedialog__crawlers__chips {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
}
.deletedialog__crawlers__chips__chip {
  margin-right: 8px;
  margin-top: 4px;
  background-color: #e8f1f2;
}
