:root {
  --animation-duration: 5s;
  --animation-timing-function: linear;
  --animation-fill-mode: forwards;
}

.toast {
  position: absolute;
  z-index: 100;
  top: 25px;
  right: 30px;
  border-radius: 12px;
  background: #fff;
  padding: 20px 35px 20px 25px;
  box-shadow: 0 6px 20px -5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.35);
}

.toast.active {
  transform: translateX(0%);
}

.toast .toast-content {
  display: flex;
  align-items: center;
  width: 400px;
}

.toast-content .severityicon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 35px;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
}
.toast-content .check {
  background-color: #2e7d32;
}
.toast-content .warning {
  background-color: #ef6c00;
}
.toast-content .error {
  background-color: #d32f2f;
}
.toast-content .info {
  background-color: #0288d1;
}

.toast-content .toat__message {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.toat__message .text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}

.toat__message .text.text-1 {
  font-weight: 600;
  color: #333;
}

.toast .close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}

.toast .close:hover {
  opacity: 1;
}

.toast .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
}

.toast .progress:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #2770ff;
}

.progress.active:before {
  animation: progress var(--animation-duration) var(--animation-timing-function)
    var(--animation-fill-mode);
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
