.dashboard__graph__filters {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 20px;
}
.crawlertype__select {
  display: flex;
  font-weight: 400;
  letter-spacing: 0.15px;
  width: fit-content;
  height: 20px;
  align-self: center;
}
.daterange__select {
  max-width: 250px;
  margin-left: 8px;
  border-radius: 4px;
  border-color: #e8f1f2;
  color: #13293d;
  font-size: 10px;
}
.daterange__select:active {
  border-color: #e8f1f2;
}
.daterange__select:hover {
  border-color: #e8f1f2 !important;
}
.daterange__select:focus {
  border-color: #e8f1f2 !important;
}
.form__selectitems {
  font-size: 11px;
  margin-left: 0px;
  padding: 12px;
}

.MuiMenuItem-root {
  height: 25px;
}
