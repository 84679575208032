.list__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 100%;
}

.list__container__loading {
  align-items: center;
  justify-content: center;
}

.list__item {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #13293d;
  padding: 0px 8px;
  margin: 4px 0;
  border: 1px solid #e2e6e8;
  border-radius: 8px;
  border-left: 6px solid #e2e6e8;
}
.list__item:hover {
  border-left: 8px solid #006494;
  cursor: pointer;
}

.list__item__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background-color: #e2e6e8;
  height: 42px;
  width: 42px;
  min-width: 42px;
  margin: 10px 4px;
  font-size: 14px;
  border-radius: 20%;
}

.list__item__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
}

.list__item__title {
  text-align: center;
  font-weight: 500;
  font-size: 15px !important;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list__item__subtitle {
  text-align: center;
  font-weight: 600;
  font-size: 14px !important;
  /* background-color: #E2E6E8;
  border-radius: 8px;
  padding: 2px 4px; */
  min-width: 30px;
  color: #006494;
}

@media (min-width: 1200px) {
  .list__item__title {
    max-width: 140px;
  }
}

@media (min-width: 1400px) {
  .list__item__title {
    max-width: 180px;
  }

  .list__item__badge {
    height: 48px;
    width: 48px;
    min-width: 48px;
  }
}

@media (min-width: 1600px) {
  .list__item__title {
    max-width: 220px;
  }
}
