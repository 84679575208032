.actions__viewiconbtn {
  color: #13293d;
  border-radius: var(--borderRadius);
  border: 1px solid #006494;
  width: 37px;
  height: 36px;
}
.actions__viewicon {
  width: 20px;
  height: 20px;
}
