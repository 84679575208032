.dashboard {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  padding: 8px 0px;
  padding-bottom: 12px;
}

.dashboard__container__wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  height: 220px;
}

.dashboard__container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.dashboard__container__graph {
  margin-top: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* flex: 1 1 auto; */
  height: 60%;
}

@media (min-width: 900px) {
  .dashboard__container {
    height: 200px;
  }

  .dashboard__container__graph {
    height: 220px;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .dashboard__container {
    height: 210px;
  }
  .dashboard__container__graph {
    height: 60%;
    margin-top: 16px;
  }
}

@media (min-width: 1254px) {
  .dashboard__container {
    height: 220px;
  }

  .dashboard__container__graph {
    height: 70%;
    margin-top: 16px;
  }
}

@media (min-width: 1400px) {
  .dashboard__container {
    height: 230px;
  }

  .dashboard__container__graph {
    height: 75%;
    margin-top: 16px;
  }
}

@media (min-width: 1600px) {
  .dashboard__container {
    height: 240px;
  }

  .dashboard__container__graph {
    height: 80%;
    margin-top: 16px;
  }
}

@media (min-width: 1700px) {
  .dashboard__container {
    height: 250px;
  }

  .dashboard__container__graph {
    height: 85%;
    margin-top: 16px;
  }
}

.dashboard__summary {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 10px 12px;
  overflow: hidden;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}

.dashboard__table__container {
  flex: 1;
  padding: 10px 12px;
  overflow: hidden;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}

.dashboard__graph__container {
  flex: 1;
  padding: 10px 12px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}

.dashboard__summary__header {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
}

.dashboard__summary__content {
  padding-top: 8px;
}

.dashboard__table__header {
  margin: 0;
}

.dashboard__table {
  margin-top: 8px;
  overflow-y: auto;
}

.dashboard__graph__header {
  margin: 0;
}

.dashboard__graph {
  margin-top: 8px;
}

.dashboard__summary__title {
  color: #006494;
  font-weight: 700;
  text-transform: none;
  width: fit-content;
}
.dashboard__summary__subtitle {
  margin-top: 2px;
  width: fit-content;
  text-transform: none;
}
