.detailsdialog {
  min-width: 500px;
  max-width: calc(70vw - 240px);
  display: flex;
  flex-direction: column;
}

.detailsdialog__title {
  text-transform: none;
  font-weight: 400;
  width: 100%;
  background-color: #e8f1f2;
  text-align: center;
  padding: 8px 12px;
  margin-bottom: 4px;
}

.detailsdialog__loader {
  min-height: fit-content;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsdialog__bubble_container {
  margin-top: 8px;
  margin-bottom: 10px;
  display: flex;
  font-family: sans-serif;
  font-size: 14px;
}

.detailsdialog__bubble_text {
  align-items: center;
  white-space: pre-wrap;
}

.detailsdialog__bubble_direction_reverse {
  flex-direction: row-reverse;
}

.detailsdialog__bubble {
  background-color: #f2f2f2;
  border-radius: 5px;
  display: block;
  padding: 10px 18px;
  position: relative;
  color: rgba(19, 41, 61, 0.87);
  word-wrap: break-word;
}

.detailsdialog__bubble::before {
  background-color: #f2f2f2;
  content: '\00a0';
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform: rotate(29deg) skew(-35deg);
  -moz-transform: rotate(29deg) skew(-35deg);
  -ms-transform: rotate(29deg) skew(-35deg);
  -o-transform: rotate(29deg) skew(-35deg);
  -webkit-transform: rotate(29deg) skew(-35deg);
  width: 15px;
}

.detailsdialog__bot_response {
  background-color: rgb(211, 229, 243);
  margin-left: 18px;
  margin-right: 60px;
}

.detailsdialog__bot_response::before {
  left: -5px;
  background-color: rgb(211, 229, 243);
}

.detailsdialog__user_question {
  background-color: rgb(238, 246, 251);
  margin-left: 60px;
  margin-right: 18px;
}

.detailsdialog__user_question::before {
  right: -5px;
  background-color: rgb(238, 246, 251);
}

.detailsdialog__img_circle {
  padding: 4px;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  background-color: #e8f1f2;
  object-fit: contain;
}

.detailsdialog__chats {
  position: relative;
  height: 100%;
}

.detailsdialog__chat_list {
  height: calc(100% - 3rem);
  padding: 0.5rem;
  /* padding-bottom: 0.5rem; */
}

.detailsdialog__url_link {
  text-decoration: underline;
  color: blue;
}

.detailsdialog__url_link:hover {
  color: darkblue;
}

.detailsdialog__url_link:visited {
  color: purple;
}

.detailsdialog__url_link:visited:hover {
  color: rgb(78, 0, 78);
}

.detailsdialog__bullet_point {
  list-style-position: inside;
}
