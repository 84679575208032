.dialog__actionbtns {
  display: flex;
  margin-top: 32px;
  min-width: 385px;
}
.dialog__cancelbtn {
  height: 44px;
  min-width: 180px;
  margin-right: 24px;
  padding: 14px 20px;
  border-radius: 4px;
  border: 1px solid #006494;
  background: #ffffff;
  color: #006494;
  font-size: 14px;
  font-weight: 500;
}
.dialog__confirmbtn {
  height: 44px;
  min-width: 180px;
  padding: 14px 20px;
  border-radius: 4px;
  background: #006494;
  color: #f5f5f5;
  font-size: 14px;
  font-weight: 500;
}
