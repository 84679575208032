.summary__card {
  padding: 12px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid #006494;
  /* border: 1px solid rgba(19, 41, 61, 0.1); */
  background: var(---Gray-White, #fff);
  /* box-shadow:
    0px 20px 20px 0px rgba(0, 0, 0, 0.02),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background: rgba(232, 241, 242, 0.2); */
}

.summary__card__value {
  color: #006494;
  font-weight: 700;
  margin-left: 4px;
}

.summary__card__title {
  color: #13293d;
  font-weight: 400;
  margin-left: 12px;
}

.summary__card__icon__container {
  background-color: #f1f7f7;
  border-radius: 50%;
  height: 40px !important;
  width: 40px !important;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary__card__icon {
  width: 25px;
  height: 25px;
}

@media (max-width: 1200px) {
  .summary__card {
    padding: 8px 12px;
  }
  .summary__card__icon__container {
    height: 32px;
    width: 32px;
  }
  .summary__card__icon {
    width: 22px;
    height: 22px;
  }
}
