.filter__dialog {
  display: flex;
  flex-direction: column;
  width: calc(70vw - 240px);
}

.filter__dialog__title {
  height: 30px;
  color: #006494;
  font-size: 23px;
  font-weight: 700;
  text-transform: none;
}
.filter__dialog__subtitle {
  height: 21px;
  color: #13293d;
  font-size: 16px;
  font-weight: 500;
}

.filter__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
}

.filter__types {
  width: 35%;
}

.filter__divider {
  margin: 0px 56px;
}

.filter__timefilter {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter__timepickers {
  display: flex;
  flex-direction: column;
}
.filter__lastDatepick {
  display: flex;
  margin-top: 12px;
}
.filter__lastDate {
  border-radius: var(--none, 0px);
  border: 1px solid #e8f1f2;
  margin-right: 12px;
  padding: 10px 12px;
  cursor: pointer;
}
.filter__lastDate:hover {
  background: #e8f1f2;
  border: 1px solid #006494;
}
.selectedDates {
  background: #e8f1f2;
  border: 1px solid #006494;
}

.filter__datepicker {
  margin-top: 24px;
  margin-bottom: 12px;
}
.filter__datepickerlabel {
  font-weight: 400;
}
.filter__datepickerInput {
  margin-top: 16px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid #e7eaec;
  width: 142px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
}
