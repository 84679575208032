.configuration__container {
  padding: 15px 0px;
  height: 100%;
}

.configuration__context {
  display: flex;
  flex-direction: column;
}

.configuration__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e8f1f2;
}
.configuration__runbutton {
  margin-right: 16px;
}
.configuration__addbutton {
  padding: 9px 28px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #006494;
  backdrop-filter: blur(0px);
}

.configuration__tablemenubar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.configuration__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  overflow-y: auto;
}
