.searchbar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchbar__input {
  width: 30%;
  min-width: 286px;
}

.searchbtn {
  margin-left: 12px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: fit-content;
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #006494;
  backdrop-filter: blur(0px);
}
