/* Message.css */
.message {
  display: flex;
  align-items: flex-end;
  margin-top: 12px;
}

.message-ai {
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-self: flex-start;
}

.message-user {
  align-self: flex-end;
  flex-direction: row;
}

.message__chat {
  width: 100%;
  min-width: none !important;
  width: fit-content !important;
  margin-right: 6px;
}

.message__chat__bubble {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
}
.message__chat__bubble__typewritter {
  text-transform: none;
  white-space: pre-wrap;
  line-height: 1.5;
  color: #13293dde;
  font-size: 14px;
  font-weight: 400;
}
.message__chat__bubble__text {
  text-transform: none;
  white-space: pre-wrap;
}

.bubble-ai {
  background-color: #cfe6fc;
}

.bubble-user {
  background-color: #e7f3ff;
}

.timestamp {
  text-align: left;
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  width: max-content;
}

.message__avatar {
  width: 32px;
  height: 32px;
  border: 1px solid #e2e2e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message__avatar__img {
  width: 24px;
  height: 24px;
}

.message__avatar__icon {
  width: 24px;
  height: 24px;
  color: #303030;
}
