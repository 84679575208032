:root {
  --borderRadius: 4px;
  --inputBorderColor: rgba(0, 0, 0, 0.23);
}

.onboard__container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f6fcff;
  /* background: #C1C3C5; */
}
.onboard__content {
  display: flex;
  width: 65%;
  height: 80%;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e6f2f4;
  box-shadow:
    0 5px 10px 0 rgba(145, 145, 145, 0.16),
    0 4px 20px 0 rgba(157, 157, 157, 0.12);
}
.onboard__left__panel {
  padding: 8px 28px;
  height: 100%;
  background-color: #f4f8fa;
  border-radius: 20px 0 0 20px;
  border-right: 1px solid #e6f2f4;
  flex-direction: column;
  justify-content: center;
}

.onboard__title__container {
  margin: 16px 0;
}
.onboard__title {
  color: #004b6f;
  text-transform: none;
}
.onboard__subtitle {
  color: #13293d;
  margin-top: 6px;
}

.onboard__steps__preview {
  margin-top: 36px;
}
.onboard__steps__title {
  margin-bottom: 12px;
  color: #888b8f;
}

.onboard__right__panel {
  display: flex;
  padding: 8px 28px;
  padding-top: 24px;
  height: 100%;
  border-radius: 0 20px 20px 0;
  flex-direction: column;
  justify-content: space-between;
}

.onboard__logo {
  margin-top: 10px;
  width: 100px;
  height: 90px;
}

.onboard__inputs {
  width: 500px;
  margin-top: 12px;
  padding: 9px 0px;
  display: flex;
  min-height: 24px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid var(--components-input-outlined-enabled-border, rgba(0, 0, 0, 0.23));
}

.onboard__btn {
  width: auto;
  margin: 16px 0px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
}
.onboard__or {
  margin: 0px 6px;
  font-weight: 400;
}

.onboard__step__content {
  height: 100%;
  border-bottom: 1px solid #e8f1f2;
}
.onboard__step__btns__container {
  align-self: flex-end;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.onboard__next__btn {
  height: 36px;
  width: fit-content;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
}
.onboard__back__btn {
  height: 36px;
  width: fit-content;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  margin-right: 20px;
}
.onboard__skip__btn {
  height: 36px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  margin-left: 20px;
  align-self: flex-start;
  background-color: white;
}

.onboard__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onboard__logout__btn {
  height: 32px;
  min-width: 0px !important;
  width: fit-content !important;
  padding: 2px 12px !important;
  text-transform: none;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  font-size: 12px;
}

@media (max-width: 1400px) {
  .onboard__content {
    width: 90%;
    height: 90%;
  }
  .onboard__getstarted__step__img {
    height: 250px;
  }
}

@media (max-width: 1000px) {
  .onboard__getstarted__step__img {
    height: 220px;
  }
}

@media (min-width: 1800px) {
  .onboard__getstarted__step__img {
    height: 350px;
  }
}
