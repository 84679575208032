.onboard_steps_title {
  color: #004b6f;
  font-weight: 700;
  text-transform: none;
}

.onboard__getstarted__step__subtitle {
  margin-bottom: 6px;
  font-weight: 600;
}

.ant-carousel .slick-dots li button {
  background: #304a93;
  opacity: 0.4;
  height: 6px;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #304a93;
}
.onboard__getstarted__step__img {
  height: 300px;
  object-fit: contain;
}

.onboard__getstarted__userguide__text {
  font-weight: 500;
  text-transform: none;
}

.onboard__getstarted__userguide__btn {
  height: 24px;
  min-width: 80px !important;
  width: fit-content !important;
  padding: 2px 8px !important;
  margin-top: 3px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  font-size: 12px;
}
