:root {
  --borderRadius: 4px;
}

.table__component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.table__container {
  width: 100%;
  overflow-y: auto !important;
}
.table__container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.table__container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.table__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.35);
}

.table__pagination {
  margin-top: 24px;
  align-self: flex-end;
  justify-self: flex-end;
}
