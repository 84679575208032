.auditing__container {
  padding: 15px 0px;
  height: 100%;
}

.auditing__context {
  display: flex;
  flex-direction: column;
}

.auditing__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 14px;
  padding-bottom: 13px;
  border-bottom: 1px solid #e8f1f2;
}

.auditing__tablemenubar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.auditing__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
  overflow-y: auto;
}
