.nodatafound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nodatafound_error_icon {
  width: 40px;
  height: 40px;
}
