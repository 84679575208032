.onboard_steps_title {
  color: #004b6f;
  font-weight: 700;
}

.onboard__integrate__subtitle {
  margin-top: 16px;
  font-weight: 500;
  line-height: 20px;
}
.onboard__integrate__btn {
  height: 28px;
  min-width: 0px !important;
  width: fit-content !important;
  padding: 2px 12px !important;
  margin-bottom: 24px;
  margin-top: 12px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
}
.onboard__teams__integrate__steps {
  border: 1px solid #e8f1f2;
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 4px;
}
.onboard__teams__integrate__steps__title {
  font-weight: 600;
  color: #004b6f;
  font-size: 15px;
  margin-bottom: 6px;
  margin-top: 16px;
  text-transform: none;
}
.onboard__teams__integrate__download__step {
  text-transform: none;
}
.onboard__teams__integrate__step {
  margin-bottom: 4px;
  text-transform: none;
}
.onboard__teams__integrate__step__guide {
  color: blue;
  cursor: pointer;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: underline;
}

.onboard__manifest__download__btn {
  height: 24px;
  min-width: 80px !important;
  width: fit-content !important;
  padding: 2px 8px !important;
  margin-top: 6px;
  margin-left: 12px;
  text-transform: none;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.46px;
  font-size: 12px;
}
.onboard__teams__integrate__confirm {
  margin-top: 8px;
}
.onboard__teams__integrate__confirm__title {
  font-size: 12px;
  text-transform: none;
}
.onboard__manifest__confirm__checkbox {
  padding: 0px !important;
  margin-right: 6px;
}
