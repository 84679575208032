.insights__component {
  width: 100%;
  height: 100vh !important;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insights__tabcontent {
  width: 100%;
  height: calc(100vh - 70px);
}
