.filter__timefilter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter__timepickers {
  display: flex;
  flex-direction: column;
}
.filter__lastDatepick {
  display: flex;
  margin-top: 12px;
}
.filter__lastDate {
  border-radius: var(--none, 0px);
  border: 1px solid #e8f1f2;
  margin-right: 12px;
  padding: 10px 12px;
  cursor: pointer;
}
.filter__lastDate:hover {
  background: #e8f1f2;
  border: 1px solid #006494;
}
.selectedDates {
  background: #e8f1f2;
  border: 1px solid #006494;
}

.filter__datepicker {
  margin-top: 24px;
  margin-bottom: 12px;
  justify-content: space-evenly;
}
.filter__datepickerlabel {
  font-weight: 400;
}
.filter__datepickerInput {
  margin-top: 16px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid #e7eaec;
  width: 142px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
}
