.edit__dialog {
  display: flex;
  flex-direction: column;
  min-width: calc(65vw - 240px);
  max-width: calc(90vw - 240px);
  max-height: calc(100vh - 130px);
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f6f6f6e0;
}
*::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: #8a8a8aa3;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.edit__dialog__title {
  height: 30px;
  color: #006494;
  font-size: 23px;
  font-weight: 700;
  text-transform: none;
}
.edit__dialog__subtitle {
  height: 21px;
  color: #13293d;
  font-size: 16px;
  font-weight: 500;
}

.edit__dialog__form {
  margin-top: 16px;
  padding: 20px 24px 24px 24px;
  border-radius: 4px;
  border: 1px solid #e8f1f2;
  bottom: 36px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
}
.edit__dialog__formtitle {
  height: 21px;
  color: #13293d;
  font-size: 16px;
  font-weight: 500;
}

.edit__dialog__inputscontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 35vh;
  padding: 0px 4px;
  overflow-y: auto;
}

.edit__dialog__formInputs {
  height: 42px !important;
  margin-top: 12px;
  /* padding: 2px 12px; */
  display: flex;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  /* border-radius: var(--borderRadius, 4px); */
  /* border: 1px solid #E7EAEC; */
}

.edit__dialog__formSelect {
  height: 42px;
  margin-top: 12px;
  /* padding: 2px 12px; */
  display: flex;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  /* border-radius: var(--borderRadius, 4px); */
  /* border: 1px solid #E7EAEC; */
}
.form__selectitems {
  padding: 9px 12px;
  max-height: 42px;
}

/* radio group styling */
.add__dialog__formRadio__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px !important;
  margin: 5px 0;
}
.add__dialog__formRadio__label {
  margin-right: 12px;
  margin-left: 4px;
  color: #13293d;
  font-size: 15px;
  font-weight: 500;
}
.access__scope__input {
  margin-top: 0px;
}

.edit__dialog__scheduler {
  display: flex;
  justify-content: space-between;
}

.scheduler__container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-right: 32px;
  justify-content: center;
}
.scheduler__content {
  display: flex;
  margin-top: 8px;
}
.scheduler__title {
  color: #13293d;
  font-size: 16px;
  font-weight: 500;
  text-transform: none !important;
}
.scheduler__title span {
  color: grey;
  font-size: 14px;
}
.scheduler__label {
  color: #13293d;
  font-size: 16px;
  font-weight: 400;
}

.scheduler__time {
  display: flex;
  flex-direction: column;
}
.scheduler__Select {
  height: 42px;
  max-width: 180px;
  margin-top: 4px;
  /* padding: 9px 12px; */
  display: flex;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  /* border-radius: var(--borderRadius, 4px); */
  /* border: 1px solid #E7EAEC; */
}

.scheduler__daySelect__input {
  display: flex;
  gap: 0.5;
  overflow-x: auto;
}
.scheduler__daySelect__input::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scheduler__daySelect__input::-webkit-scrollbar {
  height: 2px;
  width: 2px !important;
  background-color: #f5f5f5;
}
.scheduler__daySelect__input::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.35);
}
.scheduler__daySelect__chip {
  margin: 0px 2px;
}
