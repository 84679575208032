.detailsdialog {
  min-width: 500px;
  max-width: calc(70vw - 240px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.detailsdialog__title {
  text-transform: none;
  font-weight: 400;
  width: 100%;
  background-color: #e8f1f2;
  text-align: center;
  padding: 8px 12px;
  margin-bottom: 4px;
}

.detailsdialog__loader {
  min-height: fit-content;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailsdialog__content {
  width: 100%;
  max-height: 65vh;
  overflow: auto;
}
.detailsdialog__row {
  width: 100%;
  padding: 8px 12px;
  border-bottom: 1px solid #e8f1f2;
}
.detailsdialog__row__key {
  width: 40%;
}
.detailsdialog__row__value {
  width: 60%;
}
