.insights__dashboard {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  padding: 8px 0px;
  padding-bottom: 12px;
}

/* insights__dashboard summary */
.insights__dashboard__summary {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  height: 148px;
  padding: 10px 12px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}
.insights__dashboard__summary__title {
  color: #006494;
  font-weight: 700;
  text-transform: none;
  width: fit-content;
}
.insights__dashboard__summary__subtitle {
  margin-top: 2px;
  width: fit-content;
  text-transform: none;
}

.insights__dashboard__summary__content {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  height: fit-content;
  width: 100%;
}

/* insights__dashboard container */
.insights__dashboard__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* flex: 1 1 auto; */
  height: calc(100% - 148px);
}

.insights__dashboard__content {
  margin-top: 16px;
  height: 100%;
}
.insights__dashboard__graph__container {
  display: flex;
  height: '100%';
  width: '100%';
  flex-direction: 'column';
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}
.insights__dashboard__header {
  padding: 10px 12px;
  width: 100%;
  display: flex;
  flex-flow: column;
  flex: 0 1 auto;
  justify-content: space-between;
}

.insights__dashboard__table__container {
  width: 100%;
  /* height: 50%; */
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(19, 41, 61, 0.1);
  background: rgba(232, 241, 242, 0.2);
}

.insights__dashboard__table__content {
  padding: 0px 12px;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
}

@media (min-width: 900px) {
  .insights__dashboard__summary {
    height: 130px;
  }
  .insights__dashboard__container {
    height: calc(100% - 130px);
  }
  .insights__dashboard__content {
    margin-top: 12px;
    height: 100%;
    padding-bottom: 8px;
  }
}

@media (min-width: 1200px) {
  .insights__dashboard__summary {
    height: 148px;
  }
  .insights__dashboard__container {
    height: calc(100% - 148px);
    margin-top: 6px;
    padding-bottom: 0px;
  }
}

@media (min-width: 1600px) {
  .insights__dashboard__summary {
    height: 160px;
  }
  .insights__dashboard__container {
    height: calc(100% - 160px);
    margin-top: 8px;
    padding-bottom: 0px;
  }
}
