.card {
  padding: 10px;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid #006494;
  background: var(---Gray-White, #fff);
  /* box-shadow:
    0px 20px 20px 0px rgba(0, 0, 0, 0.02),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card__title__container {
  flex: 2;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card__value__container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.card__value {
  color: #006494;
  font-weight: 700;
  margin-right: 3px;
}

.card__title {
  color: #13293d;
  font-weight: 400;
  margin-left: 15px;
}

@media (min-width: 1600px) {
  .insights__dashboard__summary {
    padding: 20px 12px;
  }
}
