.tableheader__cell {
  padding: 2px 8px !important;
}

.tableheader__cellcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tableheader__checkboxcell {
  display: flex;
  align-items: center;
}

.tableheader__sorticon {
  width: '20px';
  height: '20px';
}
